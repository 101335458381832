<template>
    <v-container fluid fill-height class="py-0">
        <v-row justify="center">
            <v-card width="100vw" min-height="100vh" class="pt-10">
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-card-title>
                        <v-row align="center" justify="center">
                            <v-col
                                cols="10"
                                md="8"
                                sm="8"
                                class="text-md-h2 font-weight-bold text-sm-h4"
                            >
                                <center>{{ title }}</center>
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" class="mx-0 px-0" justify="center">
                            <v-col cols="12" sm="9">
                                <v-tabs>
                                    <v-tab href="#editor">Editor</v-tab>
                                    <v-tab-item value="editor" class="mt-5">
                                        <v-textarea
                                            class="mb-0"
                                            name="input-7-1"
                                            placeholder="Content in Markdown or HTML Format"
                                            :rows="row"
                                            :value="mdSource"
                                            @change="(v) => (mdSource = v)"
                                        ></v-textarea>
                                    </v-tab-item>
                                    <v-tab href="#preview">Preview</v-tab>
                                    <v-tab-item value="preview" class="mt-5">
                                        <VueShowdown
                                            flavor="github"
                                            :markdown="mdSource"
                                            class="markdown-body mb-10"
                                            :options="VueShowdownOptions"
                                        />
                                    </v-tab-item>
                                </v-tabs>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="10" sm="10" align="right">
                                <v-btn class="" color="primary lighten-2" @click="saveData()">
                                    <v-icon dark left> mdi-content-save </v-icon> Submit
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-form>
            </v-card>
            <div>
                <v-btn fab class="red white--text" fixed @click="$router.go(-1)" right top>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
        </v-row>
    </v-container>
</template>

<script>
import { VueShowdown } from "vue-showdown";
import hljs from "highlight.js";
import "highlight.js/styles/xt256.css";
import "@/assets/css/markdown.css";
import axios from "axios";

const highlightCode = () => {
    const preEl = document.querySelectorAll("pre code");

    preEl.forEach((el) => {
        hljs.highlightElement(el);
    });
};

export default {
    name: "AboutForm",
    components: {
        VueShowdown
    },
    data: () => ({
        loading: false,
        row: 0,
        valid: true,
        title: "",
        mdSource: "",
        VueShowdownOptions: { emoji: true, tables: true }
    }),
    methods: {
        async saveData() {
            this.$toast.info("Saving the Data...");
            let jsonData = {
                data: this.mdSource
            };
            try {
                let response;
                response = await axios.patch("/api/about/" + this.$route.params.id, jsonData, {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json"
                    }
                });

                if (response.status == 204) {
                    this.$toast.success("Data Saved", {
                        onClose: () => {
                            this.$router.push({ path: "/about" });
                        }
                    });
                } else {
                    this.$toast.error("Oh Shit! Failed! :(");
                }
            } catch (e) {
                this.$toast.error("Oh Shit! Failed! :(");
            }
        }
    },
    async created() {
        this.row = this.$vuetify.breakpoint.smAndUp ? 20 : 10;
        let part = this.$route.params.id;
        let resp = await axios.get("/api/about/" + part);
        if (part == "story") {
            this.title = "My Short Story";
            this.mdSource = resp.data.Story;
        } else if (part == "project") {
            this.title = "My Projects";
            this.mdSource = resp.data.Project;
        }
    },
    mounted() {
        highlightCode();
    },
    updated() {
        highlightCode();
    }
};
</script>

<style scoped>
.v-text-field >>> input {
    font-size: 1.2em;
}
</style>
